import { createStore } from "vuex";

export default createStore({
    state: {
        bpjsPriceControlItems: [], // Ini adalah state yang akan di-set dan di-get
    },
    mutations: {
        setBpjsPriceControlItems(state, payload) {
            state.bpjsPriceControlItems = payload; // Mutasi untuk mengubah state
        },
    },
    actions: {
        updateBpjsPriceControlItems({ commit }, payload) {
            commit("setBpjsPriceControlItems", payload); // Action untuk meng-commit mutasi
        },
    },
    getters: {
        getBpjsPriceControlItems: (state) => {
            return state.bpjsPriceControlItems; // Getter untuk mengambil nilai state
        },
    },
});
